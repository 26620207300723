/*
 * Create a custom html `a` tag on the page and
 * immediately execute 'click' action on it
 *
 * @param data - file/blob data to create downloadable file link
 * @param fileName - the name of downloadable file
 */
export const createDownloadableLink = (data, fileName) => {
  if (!document) return;
  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(link.href);
  link.remove();
};
