<template>
  <v-btn
    :large="large"
    color="primary"
    outlined
    rounded
    variant="elevated"
    @click="$emit('click')"
  >
    Clear filters
  </v-btn>
</template>

<script>
export default {
  name: "CancelButton",

  emits: ["click"],

  props: {
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
