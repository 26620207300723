<template>
  <v-select
    :value="status"
    :items="items"
    :hide-details="true"
    :label="label"
    :menu-props="{ bottom: true, offsetY: true }"
    outlined
    clearable
    @change="hanleUpdate"
    @click:clear="handleClear"
  >
    <template v-slot:selection="{ item }">
      <div class="d-flex flex-start align-center">
        <CliniciansNameBadge :clinician-status="item" class="mr-2" />
        <span>{{ item }}</span>
      </div>
    </template>

    <template v-slot:item="{ item }">
      <div class="d-flex flex-start align-center">
        <CliniciansNameBadge :clinician-status="item" class="mr-2" />
        <span>{{ item }}</span>
      </div>
    </template>
  </v-select>
</template>

<script>
import { ClinicianStatuses } from "@/misc/constants";
import CliniciansNameBadge from "../common/CliniciansNameBadge.vue";

export default {
  name: "ClinicianStatusesSelect",

  components: {
    CliniciansNameBadge,
  },

  props: {
    status: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "Status",
    },
  },

  data() {
    const items = Object.values(ClinicianStatuses);

    return {
      items,
    };
  },

  methods: {
    hanleUpdate(value) {
      this.$emit("update", value);
    },
    handleClear() {
      this.data = "";
      this.$emit("update", "");
    },
  },
};
</script>
