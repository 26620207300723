export function get(object, path, defaultVal) {
  const dot = typeof path === "string" ? path.indexOf(".") : -1;

  if (typeof object !== "object" || object === null || object === undefined) {
    return defaultVal;
  }

  if (dot === -1) {
    if (path.length && path in object) {
      return object[path] ?? defaultVal;
    }
    return defaultVal;
  }

  return get(object[path.substr(0, dot)], path.substr(dot + 1), defaultVal);
}
