<template>
  <v-btn
    :disabled="disabled"
    :large="large"
    rounded
    color="primary"
    variant="elevated"
    @click="$emit('click')"
  >
    Apply filters
  </v-btn>
</template>

<script>
export default {
  name: "ApplyButton",

  emits: ["click"],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
