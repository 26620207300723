<template>
  <v-select
    :value="value"
    :items="options"
    :loading="loading"
    :menu-props="menuProps"
    :label="label"
    multiple
    outlined
    clearable
    hide-details
    @click:clear="$emit('change', null)"
    @change="$emit('change', $event)"
  >
    <template #prepend-item>
      <div class="clinical-codes-multiselect__search">
        <v-list-item>
          <v-list-item-content class="pt-2 pb-0">
            <v-text-field
              placeholder="Search clinician"
              prepend-inner-icon="mdi-magnify"
              dense
              outlined
              clearable
              hide-details
              @input="onSearch"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </div>
    </template>

    <template v-slot:selection="data">
      <v-chip
        v-if="data.index < 2"
        label
      >
        {{ data.item.text }}
      </v-chip>
      <span v-if="data.index === 2">...</span>
    </template>
  </v-select>
</template>

<script>
import { debounce } from "lodash";

const menuProps = {
  bottom: true,
  offsetY: true,
  left: true,
};

export default {
  name: "CliniciansMultiselect",

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    service: {
      type: String,
      default: () => ""
    },
    label: {
      type: String,
      default: () => ""
    },
    selector: {
      type: Function,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      clinicians: [],
      menuProps,
    };
  },

  computed: {
    options() {
      if (!this.clinicians.length) return [];
      return this.clinicians.map((clinician) => ({
        value: clinician._id,
        text: clinician.name,
      }));
    },
    selectedClinicians() {
      if (!this.value || !this.clinicians.length) return [];
      return this.value.map((name) =>
        this.clinicians.find(
          ({ name: clinicianName }) => clinicianName === name
        )
      );
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch(search) {
      try {
        this.loading = true;
        const query = {};

        if (this.service) {
          query.filter = {
            clinicalServices: { $in: [ this.service ] }
          }
        }

        if (search?.length) {
          query.filter = {
            name: { $regex: search, $options: "i" },
            ...query.filter
          };
        }

        const { data } = await this.selector(query);
        this.clinicians = [...data, ...this.selectedClinicians];
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
      }
    },
    onSearch: debounce(function (search) {
      this.fetch(search);
    }, 800),
    onRemove({ value }) {
      const clinicians = this.value.filter((name) => value !== name);
      this.$emit("change", clinicians);
    },
  },
};
</script>
